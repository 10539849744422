body,
html {
  font-family: "Open Sans", sans-serif;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  color: #777;
  font-weight: 400;
  width: 100% !important;
  height: 100% !important;
  scroll-behavior: smooth;
}
h2,
h3,
h4 {
  font-family: "Raleway", sans-serif;
}
h2 {
  text-transform: uppercase;
  margin: 0 0 20px;
  font-weight: 1000;
  font-size: 36px;
  color: #333;
}
h3 {
  font-size: 20px;
  font-weight: 600;
  color: #333;
}
h4 {
  font-size: 18px;
  color: #333;
  font-weight: 600;
}
h5 {
  text-transform: uppercase;
  font-weight: 700;
  line-height: 20px;
}
p {
  font-size: 15px;
}
p.intro {
  margin: 12px 0 0;
  line-height: 24px;
}
a {
  color: #608dfd;
  font-weight: 400;
}
a:hover,
a:focus {
  text-decoration: none;
  color: #608dfd;
}
ul,
ol {
  list-style: none;
}
ul,
ol {
  padding: 0;
  webkit-padding: 0;
  moz-padding: 0;
}
hr {
  height: 2px;
  width: 70px;
  text-align: center;
  position: relative;
  background: #1e7a46;
  margin-bottom: 20px;
  border: 0;
}
.section-title {
  margin-bottom: 10px;
}

.section-title h2 {
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.section-title h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}
.section-title p {
  font-size: 18px;
}

/* Social */
.social-media {
  text-align: center;
}
.social-media a {
  display: inline-block;
  margin: 0 20px;
}
.social-media i.pi {
  color: #5b5b5b;
}
.social-media i.fa:hover {
  color: #608dfd;
  background: #000000;
}

/* Navigation Section */
#Navbar {
  transition: all 0.5s;
  scroll-behavior: smooth;
  /* z-index: 1001; */
  background: #182638;
  color: #ffffff;
}
.scrolled {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
}
#Navbar .container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  background: #182638;
  color: #ffffff;
  width: 90% !important;
  height: 100% !important;
  scroll-behavior: smooth;
  margin: 0 auto;
}
#Navbar .text {
  background: #182638;
  color: #a89200;
}
#Navbar .icon {
  background: #182638;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
#Navbar .icon .left{
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #a89200;
  margin: 5px;
}
#Navbar .icon .left i, .right i {
  margin: 5px;
}
#Navbar .icon .right{
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #a89200;
  margin: 5px;
}
#Navbar .icon p {
  color: #a89200;
}
#Navbar a {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0 10px;
  padding: 5px;
  border-radius: 5px;
  transition: all 0.3s;
  color: #ffffff;
}
#Navbar .display-none {
  display: none;
}
#Navbar p {
  color: #888;
  font-weight: 200;
  font-size: 12px;
  margin: 0;
  justify-content: left;
  color: #ffffff;
}
#Navbar .logo{
  color: #ffffff;
  cursor: pointer;
  text-align: left;
}
#Navbar .logo h3{
  color: #ffffff;
  cursor: pointer;
  font-size: 30px;
  padding: 0;
  margin: 5px 0;
}
#Navbar .nav-text{
  flex-basis: 100%;
  justify-content: space-around;
  align-items: center;
}
#Navbar .nav-text .open {
  display: block;
}
#Navbar .menu-toggle {
  display: none;
}

/* Header Section */
#header {
  color: white;
  display: table;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  height: 90vh;
  width: 100%;
}
#header .container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  background: #182638;
  color: #ffffff;
  width: 90% !important;
  height: 100% !important;
  scroll-behavior: smooth;
  margin: auto 200px;
}
#header .background {
  background: linear-gradient(rgba(0, 0, 0, 20), rgba(0, 0, 0, 0.5)), url(../img/transport.jpg);
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#header .text-container{
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}
#header .divider {
  height: 0.1px;
  width: 60%;
  background:  rgb(191, 162, 0);
  color: rgb(191, 162, 0);
  margin: 10px auto;
}
#header .button {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  color: #a89200;
  background-color: #ffffff00;
  border: 2px solid #a89200;
  padding: 14px 34px;
  letter-spacing: 1px;
  margin: 10px;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.5s linear;
}

#header .button:hover {
  background-color: #a89200;
  color: #ffffff;
}


/* About Section */
.about-section {
  padding: 60px 0;
  background-color: #f5f5f5;
}

.about-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.about-text {
  flex: 1;
  padding-right: 20px;
}

.about-text h2 {
  font-size: 36px;
  font-weight: 700;
  color: #333;
  margin-bottom: 40px;
}

.about-text p {
  font-size: 16px;
  color: #777;
  margin-bottom: 20px;
  line-height: 1.6;
}

.about-button {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  color: #a89200;
  background-color: #ffffff00;
  border: 2px solid #a89200;
  padding: 14px 34px;
  letter-spacing: 1px;
  margin: 10px;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.5s linear;
}

.about-button:hover {
  background-color: #a89200;
  color: #ffffff;
}

.about-image {
  flex: 1;
  text-align: right;
}

.about-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}


/* Uctovnictvo Section */
#uctovnictvo {
  padding: 50px 20px;
  background: linear-gradient(to right, #b1b1b1 0%, #8a8a8a 100%);
  color: #fff;
}

#uctovnictvo .container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

#uctovnictvo .section-title {
  margin-bottom: 30px;
}

#uctovnictvo .section-title h2 {
  font-size: 36px;
  color: #ffffff; 
}

#uctovnictvo .section-items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

#uctovnictvo .item {
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  text-align: left;
}

#uctovnictvo .item i {
  font-size: 40px;
  color: #ffffff; /* Yellow color for icons */
  margin-right: 20px;
}

#uctovnictvo .item h3 {
  font-size: 20px;
  color: #ffffff; /* Yellow color for item titles */
  margin-bottom: 10px;
}

#uctovnictvo .item p {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.75);
}

/* Autodoprava Section */
#autodoprava {
  padding: 50px 20px;
  background: #f5f5f5; 
  color: #333; 
}

#autodoprava .container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

#autodoprava .section-title {
  margin-bottom: 30px;
}

#autodoprava .section-title h2 {
  font-size: 36px;
  font-weight: 700;
  color: #000000; /* red color for "Services" */
}

#autodoprava .section-title h2 span {
  color: #000; /* black color for "My" */
}

#autodoprava .section-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

#autodoprava .item {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  text-align: center;
}

#autodoprava .item:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

#autodoprava .item i {
  font-size: 40px;
  color: #000000; /* red color for icons */
  margin-bottom: 15px;
}

#autodoprava .item h3 {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 15px;
}

#autodoprava .item p {
  font-size: 14px;
  color: #777;
  margin: 0;
}


/* Gallery Section */
#gallery {
  padding: 10px 0;
}
#gallery .container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}
#gallery .gallery-items {
  padding: 10px;
}
#gallery .gallery-item {
  margin: 1px -15px 0 -14px;
  padding: 0;
}
#gallery .gallery-item {
  overflow: hidden;
  position: relative;
  margin: 0;
}
#gallery .gallery-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
}
#gallery img {
  width: 200px;
  height: 200px;
  object-fit: cover;
}
#gallery .grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
#gallery .grid img {
  width: 100%;
  cursor: pointer;
}
#gallery .modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
#gallery .modal img {
  max-width: 90%;
  max-height: 90%;
}

/* Klienti Section */
#klienti {
  padding: 60px 0;
  background: #ffffff; 
}
#klienti .container {
  margin: auto 200px;
  padding: 10px 0;
}

.section-title h2 {
  font-size: 36px;
  font-weight: 700;
  color: #333;
  margin-bottom: 40px;
}

.row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.klienti-card {
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  margin: 15px;
  width: calc(33.333% - 40px); /* Three cards per row */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.klienti-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.klienti-image img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 15px;
  object-fit: cover;
}

.klienti-content p {
  font-size: 14px;
  color: #555;
  margin-bottom: 10px;
  font-style: italic;
}

.klienti-meta {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}


/* Team Section */
#team {
  padding: 100px 0;
}
#team h4 {
  margin: 5px 0;
}
#team .team-img {
  width: 240px;
}
#team .thumbnail {
  background: transparent;
  border: 0;
}
#team .thumbnail .caption {
  padding: 10px 0 0;
  color: #888;
}

/* Contact Section */
#contact {
  background-color: #ffffff; 
  padding: 50px;
  display: flex;
  justify-content: center;
}

.contact-container {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  background-color: #182638; 
  border-radius: 15px;
  overflow: hidden;
}

.contact-info {
  flex: 1;
  padding: 30px;
  background-color: #182638;
  color: #ffffff;
  font-family: 'Raleway', sans-serif;
}

.contact-info h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #ffffff; 
}

.contact-info ul {
  list-style-type: none;
  padding: 0;
  font-size: 16px;
}

.contact-info ul li {
  margin-bottom: 10px;
}

.contact-info ul li strong {
  font-weight: 700;
}

.social-icons {
  margin-top: 20px;
}

.social-icons a {
  color: #ffffff; 
  margin-right: 10px;
  font-size: 20px;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #a89200;
}

.contact-form {
  flex: 1;
  padding: 30px;
  background-color: #182638;
}

.contact-form .form-group {
  margin-bottom: 20px;
}

.contact-input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #ffffff; 
  color: #000000; 
  font-size: 16px;
}

.submit-btn {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  color: #a89200;
  background-color: #ffffff00;
  border: 2px solid #a89200;
  padding: 14px 34px;
  letter-spacing: 1px;
  margin: 10px;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.5s linear;
}

.submit-btn:hover {
  background-color: #a89200; 
  color: #ffffff;
}
/* API */
#api .form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #000000;
  font-size: 14px;
  padding: 10px 20px;
  margin-bottom: 20px;
  border-radius: 0.3rem;
  transition: all 0.3s;
  border-radius: 5rem;
  height: 50vh;
}
#api input {
  display: flex;
  color: #000000;
  font-size: 14px;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #000000;
  transition: all 0.3s;
  width: 20rem;
}

/* Footer Section*/
#footer {
  background: #182638;
  padding: 30px 0;
  color: #ffffff;
}
#footer h3 {
  color: #000000;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  text-align: center;
  color: #ffffff;
}
#footer .container{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  justify-items: center;
  align-items: center;
}
#footer .logo{
  display: none;
}
#footer .logo-text{
  text-align: left;
  margin: 10px;
  font-size: 20px;
  font-weight: 600;
}
#footer .navigation ul {
  padding: 0;
  margin: 0;
}
#footer .navigation ul li {
  list-style: none;
}
#footer .navigation ul li a {
  font-size: 14px;
  color: #ffffff;

}
#footer .navigation ul li a:hover {
  color: #ffffff;
}
#footer .social-media {
  text-align: center;
  display: flex;
  align-items: center;
}
#footer .social{
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
#footer .nav-text{
  justify-content: center;
}
#footer p {
  color: #888;
  font-weight: 200;
  font-size: 12px;
  margin: 0;
  text-align: center;
  color: #ffffff;
}
#footer a {
  margin: 5px 0;
  padding: 5px;
}
#footer i {
  margin: 10px;
  color: #ffffff;
  font-size: 10px;
}
#footer hr {
    height: 1px;
    left: 0;
    background:  rgb(191, 162, 0);
    width: 90%;
    color: rgb(191, 162, 0);
    margin: 0;
    margin-bottom: 10px;
}
#footer .icon {
  background: #182638;
  display: flex;
  align-items: center;
  justify-content: center;
}
#footer .icon .left{
  display: flex;
  align-items: center;
  color: #a89200;
  margin: 5px;
}
#footer .icon .left i, .right i {
  margin: 5px;
}
#footer .icon .right{
  display: flex;
  align-items: center;
  color: #a89200;
  margin: 5px;
}
#footer .icon p {
  color: #ffffff;
}
/* cookies */
#cookies {
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1002;
}
#cookies .cookies-container {
  position: fixed;
  text-align: center;
  bottom: 1%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 600px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
#cookies button {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  color: #a89200;
  background-color: #ffffff00;
  border: 2px solid #a89200;
  padding: 14px 34px; 
  letter-spacing: 1px;
  margin: 10px;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.5s linear;
}
#cookies button:hover {
  color: #fff;
  background-image: none;
  background-color: #a89200;
}
@media (min-width: 1800px) {
  #header .container {
    scroll-behavior: smooth;
    margin: auto 800px;
  }
  #uctovnictvo .section-items {
    grid-template-columns: 1fr;
  }
  #autodoprava .container {
    scroll-behavior: smooth;
    margin: auto 800px;
    padding: 100px 0;
  }
  #about .container {
    scroll-behavior: smooth;
    margin: auto 800px;
    padding: 100px 0;
  }
  #gallery .container {
    scroll-behavior: smooth;
    margin: auto 800px;
    padding: 100px 0;
  }
  #kontakt .container {
    scroll-behavior: smooth;
    margin: auto 800px;
    padding: 100px 0;
  }
  #klienti .container {
    scroll-behavior: smooth;
    margin: auto 800px;
    padding: 100px 0;
  }
  #footer .container {
    scroll-behavior: smooth;
  }
}

@media (max-width: 1400px) {
  #header .container {
    scroll-behavior: smooth;
    margin: auto 100px;
  }
  #uctovnictvo .container {
    scroll-behavior: smooth;
    margin: auto 100px;
    padding: 100px 0;
  }
  #autodoprava .container {
    scroll-behavior: smooth;
    margin: auto 100px;
    padding: 100px 0;
  }
  #about .container {
    scroll-behavior: smooth;
    padding: 100px 0;
  }
  #gallery .container {
    scroll-behavior: smooth;
    margin: auto 100px;
    padding: 100px 0;
  }
  #kontakt .container {
    scroll-behavior: smooth;
    margin: auto 100px;
    padding: 100px 0;
  }
  #klienti .container {
    scroll-behavior: smooth;
    margin: auto 100px;
    padding: 100px 0;
  }
  #footer .container {
    scroll-behavior: smooth;
  }
}

@media (max-width: 1000px) {
  #navbar .container {
    flex-direction: column;
    margin: 0;
  }
  #navbar .display-none {
    display: none;
  }
  #navbar .menu-toggle {
    display: block;
  }
  #header h1 {
    font-size: 60px;
  }
  #navbar .container {
    flex-direction: column;
    margin: 0;
  }
  #header p {
    font-size: 20px;
  }
  #header .divider {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  #about .container {
    flex-direction: column;
    margin: 10px;

  }
  #about p, h3, h2 {
    font-size: 12px;
  }
  #about .section-text h2 {
    position: relative;
    margin-top: 10px;
  }
  #about .list-style {
    margin: 0;
  }
  #about .list {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  #uctovnictvo .container{
    flex-direction: column;
    margin: 10px;
  }
  #uctovnictvo p, h3, h2 {
    font-size: 12px;
  }
  #uctovnictvo .section-items {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
  #autodoprava .container{
    flex-direction: column;
    margin: 10px;
  }
  #autodoprava .section-items {
    grid-template-columns: repeat(2, 1fr);
  }
  #autodoprava p, h3, h2 {
    font-size: 12px;
  }
  #gallery .container{
    flex-direction: column;
    margin: 10px;
  }
  #gallery p, h3, h2 {
    font-size: 12px;
  }
  #klienti .container{
    flex-direction: column;
    margin: 10px;
  }
  .klienti-card {
    width: calc(50% - 40px); 
  }
  #contact .container{
    flex-direction: column;
    margin: 10px;
  }
  #cookies {
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1002;
  }
}

@media (max-width: 768px) {
  body,
html {
  font-family: "Open Sans", sans-serif;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  color: #777;
  font-weight: 400;
  width: 100% !important;
  height: 100% !important;
  scroll-behavior: smooth;
}
#header h1 {
  font-size: 40px;
}
  #navbar .container {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  #Navbar .nav-text {
    display: none;
  }
  #Navbar .open {
    display: block;
  }
  #Navbar .button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #Navbar .menu-toggle {
    display: flex;
    background: #182638;
    color: #ffffff;
    border: none;
    cursor: pointer;
    font-size: 30px;
  }
  #Navbar .logo {
    color: #ffffff;
    cursor: pointer;
    font-size: 30px;
    padding: 0;
    margin: 0 15px;
}
#autodoprava .section-items {
  grid-template-columns: 1fr;
}
.klienti-card {
  width: 100%; 
  margin-bottom: 30px;
}
#gallery .grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
}
  #footer .container {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  #footer .nav-text {
    display: none;
  }
  #footer .open {
    display: block;
  }
  #footer .button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #footer .menu-toggle {
    display: flex;
    background: #182638;
    color: #ffffff;
    border: none;
    cursor: pointer;
    font-size: 30px;
  }
}
@media (max-width: 350px) {
    body,
    html {
    font-family: "Open Sans", sans-serif;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    color: #777;
    font-weight: 400;
    width: 100% !important;
    height: 100% !important;
    scroll-behavior: smooth;
  }
  #Navbar .container {
    flex-direction: column;
    margin: 0;
  }
  #Navbar .menu-toggle {
    display: block;
  }
  #header h1 {
    font-size: 40px;
  }
  #header p {
    font-size: 18px;
  }
  #header .divider {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  #about .container {
    flex-direction: column;
    font-size: 8px;
  }
  #about p, h3, h2 {
    font-size: 8px;
  }
  #about .container {
    flex-direction: column;
  }
  #about .section-right {
    margin-top: 20px;
  }
  #about .section-title {
    margin-top: 20px;
  }
  #about .section-text {
    margin-top: 20px;
  }
  #uctovnictvo .section-items {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
  #uctovnictvo p, h3, h2 {
    font-size: 12px;
  }
  #autodoprava p, h3, h2 {
    font-size: 12px;
  }
  #gallery .grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
}
  #footer .container {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  #footer .nav-text {
    display: none;
  }
  #footer .open {
    display: block;
  }
  #footer .button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #footer .menu-toggle {
    display: flex;
    background: #182638;
    color: #ffffff;
    border: none;
    cursor: pointer;
    font-size: 30px;
  }
  #cookies {
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1002;
  }
}

